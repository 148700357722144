import { scaleLinear } from 'd3-scale';
export default function linearScale(_ref) {
  var range = _ref.range,
      rangeRound = _ref.rangeRound,
      domain = _ref.domain,
      _ref$nice = _ref.nice,
      nice = _ref$nice === void 0 ? false : _ref$nice,
      _ref$clamp = _ref.clamp,
      clamp = _ref$clamp === void 0 ? false : _ref$clamp;
  var scale = scaleLinear();
  if (range) scale.range(range);
  if (rangeRound) scale.rangeRound(rangeRound);
  if (domain) scale.domain(domain);
  if (nice) scale.nice();
  if (clamp) scale.clamp(true); // @ts-ignore

  scale.type = 'linear';
  return scale;
}